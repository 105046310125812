import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ScientificTestMethod } from "@/types/scientific-test-method-schema";
import { ScientificTesting } from "@/types/scientific-testing";

interface ScientificTestingState {
    report: {
        scientificTesting: ScientificTesting;
        testMethods: ScientificTestMethod[];
    } | null;
}

const INITIAL_SCIENTIFIC_TESTING_STATE: ScientificTestingState = {
    report: null,
};

const scientificTestingSlice = createSlice({
    name: "scientificTesting",
    initialState: INITIAL_SCIENTIFIC_TESTING_STATE,
    reducers: {
        setReport: (state, action: PayloadAction<ScientificTestingState>) => {
            state.report = action.payload.report;
        },
        clearReport: () => INITIAL_SCIENTIFIC_TESTING_STATE,
    },
});

export const { setReport, clearReport } = scientificTestingSlice.actions;
export const scientificTestingReducer = scientificTestingSlice.reducer;
