import { clsx } from "clsx";

import styles from "@/components/spinner/spinner.module.css";

type Size = "sm" | "md";

const variantToClassMap: Record<Size, string> = {
    sm: styles.spinner_sm,
    md: styles.spinner_md,
};

interface Props {
    size?: Size;
}

export const Spinner = (props?: Props) => {
    const { size = "md" } = props ?? {};
    return <div className={clsx(styles.spinner, variantToClassMap[size])}></div>;
};
