interface ConstructPathOptions {
    path?: string;
    queryParams?: Record<string, string | number | boolean | null | undefined>;
    preserveExisting?: boolean;
}

/**
 * Constructs a relative path with optional query parameters and existing query preservation.
 *
 * @param options - The path construction options.
 * @returns The constructed path as a string.
 */
export function constructPath(options?: ConstructPathOptions): string {
    const { path = "", queryParams = {}, preserveExisting = false } = options ?? {};

    // Ensure path starts with `/`
    const normalizedPath = path.startsWith("/") ? path : `/${path}`;

    // Extract existing query parameters from `path` if `preserveExisting` is enabled
    const [basePath, existingQuery] = normalizedPath.split("?");
    const searchParams = new URLSearchParams(preserveExisting && existingQuery ? existingQuery : "");

    // Append new query parameters, ignoring `null`, `undefined`, and empty values
    Object.entries(queryParams).forEach(([key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
            searchParams.set(key, String(value)); // Overwrite existing query params if present
        }
    });

    return `${basePath}${searchParams.toString() ? `?${searchParams.toString()}` : ""}`;
}
