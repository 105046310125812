import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { clsx } from "clsx";
import { NavLink } from "react-router-dom";

import styles from "@/components/layout/sidebar/sidebar-item.module.css";
import { SidebarLinkItem } from "@/components/layout/sidebar/sidebar-items";

interface Props extends SidebarLinkItem {
    sidebarCollapsed: boolean;
}

export const SidebarItem = (props: Props) => {
    const { to, icon, name, sidebarCollapsed } = props;

    return (
        <WdsSidebar.Item asChild className={styles.sidebar_item}>
            <NavLink to={to}>
                {icon && <WdsSidebar.Item.Icon>{icon}</WdsSidebar.Item.Icon>}
                <WdsSidebar.Item.Content
                    className={clsx("fade_transition", sidebarCollapsed ? "fade_hidden" : "fade_visible")}
                >
                    {name}
                </WdsSidebar.Item.Content>
            </NavLink>
        </WdsSidebar.Item>
    );
};
