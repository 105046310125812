import { lazy } from "react";

import { PageAtRouteMap } from "@/pages/pages.types";

export const HomePage = lazy(() => import("@/pages/home-page"));
export const ChatPage = lazy(() => import("@/pages/chat-page"));
export const TrialEventListPage = lazy(() => import("@/pages/trial-event/trial-event-list-page"));
export const TrialEventCreatePage = lazy(() => import("@/pages/trial-event/trial-event-create-page"));
export const TrialEventDetailPage = lazy(() => import("@/pages/trial-event/trial-event-detail-page"));
export const TrialEventEditPage = lazy(() => import("@/pages/trial-event/trial-event-edit-page"));
export const ScientificTestingCalculatePage = lazy(
    () => import("@/pages/scientific-testing/scientific-testing-calculate-page"),
);
export const ScientificTestingReportPage = lazy(
    () => import("@/pages/scientific-testing/scientific-testing-report-page"),
);
export const PageNotFoundPage = lazy(() => import("@/pages/page-not-found-page"));

/**
 * Create an object where routes are associated the their page components
 */
export const pageAtRouteMap = {
    home: HomePage,
    chat: ChatPage,
    trialEvents: TrialEventListPage,
    trialEventsCreate: TrialEventCreatePage,
    trialEventsDetail: TrialEventDetailPage,
    trialEventsEdit: TrialEventEditPage,
    scientificTestingCalculate: ScientificTestingCalculatePage,
    scientificTestingReport: ScientificTestingReportPage,
    pageNotFound: PageNotFoundPage,
} satisfies Partial<PageAtRouteMap>;
