import { useAzureAuth } from "@datamole/wds-azure-auth";
import { Button } from "@datamole/wds-component-button";
import { Menu } from "@datamole/wds-component-menu";
import { Sidebar } from "@datamole/wds-component-sidebar";
import { faRightFromBracket, faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "@/components/layout/sidebar/user-menu/user-menu.module.css";
import { colorPalette } from "@/styles/color-palette";

const UserMenu = () => {
    const { triggerLogout, user } = useAzureAuth();

    return (
        <Menu>
            <Menu.Trigger asChild className={`rd_button ${styles.user_menu}`}>
                <Sidebar.Item>
                    <Sidebar.Item.Icon>
                        <FontAwesomeIcon icon={faUserCircle} color={colorPalette.iconGray} />
                    </Sidebar.Item.Icon>
                    <Sidebar.Item.Content>{user?.name ?? user?.username}</Sidebar.Item.Content>
                </Sidebar.Item>
            </Menu.Trigger>
            <Menu.Content align="end" side="bottom">
                <Menu.Content.List>
                    <Menu.Content.Item asChild>
                        <Button onClick={triggerLogout}>
                            <FontAwesomeIcon icon={faRightFromBracket} />
                            <Menu.Content.Item.Content>Log out</Menu.Content.Item.Content>
                        </Button>
                    </Menu.Content.Item>
                </Menu.Content.List>
            </Menu.Content>
        </Menu>
    );
};

export { UserMenu };
