import { faCalendarClock, faHouse, faMicroscope, faUserHeadset } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { SvgIcon } from "@/components/svg-icon";
import { HOMEPAGE_CHAT_HISTORY_ID, HOMEPAGE_CHAT_ID, SCROLL_TO_PARAM_KEY } from "@/constants";
import { routes } from "@/routes";
import { colorPalette } from "@/styles/color-palette";
import { constructPath } from "@/utils/construct-path";

export interface SidebarLinkItem {
    name: string;
    to: string;
    icon?: ReactNode;
}

// Defines contents for Sidebar menu
export const sidebarItems = {
    home: {
        to: constructPath({
            path: routes.home.path,
            queryParams: { [SCROLL_TO_PARAM_KEY]: HOMEPAGE_CHAT_ID },
        }),
        name: "Home",
        icon: <FontAwesomeIcon icon={faHouse} color={colorPalette.primaryBlue} />,
    },
    agents: {
        to: routes.home.path,
        name: "Agents",
        icon: <FontAwesomeIcon icon={faUserHeadset} color={colorPalette.primaryBlue} />,
    },
    chatHistory: {
        to: constructPath({
            path: routes.home.path,
            queryParams: { [SCROLL_TO_PARAM_KEY]: HOMEPAGE_CHAT_HISTORY_ID },
        }),
        name: "Chat History",
        icon: <SvgIcon iconName="message" />,
    },
    trialEvents: {
        to: routes.trialEvents.path,
        name: "Trial Events",
        icon: <FontAwesomeIcon icon={faCalendarClock} color={colorPalette.primaryBlue} />,
    },
    scientificTestingCalculate: {
        to: routes.scientificTestingCalculate.path,
        name: "Scientific Testing",
        icon: <FontAwesomeIcon icon={faMicroscope} color={colorPalette.primaryBlue} />,
    },
} satisfies Record<string, SidebarLinkItem>;
