import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { z } from "zod";

interface PinChatState {
    pinnedChatIds: string[];
}

const getStorageKey = (userId: string) => `pinnedChatIds_${userId}`;

const initialState: PinChatState = {
    pinnedChatIds: [],
};

interface PinChatPayload {
    userId: string;
    chatId: string;
}

const updateLocalStorage = (userId: string, pinnedChatIds: string[]) => {
    const storageKey = getStorageKey(userId);
    localStorage.setItem(storageKey, JSON.stringify(pinnedChatIds));
};

const pinChatSlice = createSlice({
    name: "pinChat",
    initialState,
    reducers: {
        pinChat: (state, action: PayloadAction<PinChatPayload>) => {
            const { userId, chatId } = action.payload;

            if (!state.pinnedChatIds.includes(chatId)) {
                state.pinnedChatIds.push(chatId);
                updateLocalStorage(userId, state.pinnedChatIds);
            }
        },
        unpinChat: (state, action: PayloadAction<PinChatPayload>) => {
            const { userId, chatId } = action.payload;

            state.pinnedChatIds = state.pinnedChatIds.filter((id) => id !== chatId);
            updateLocalStorage(userId, state.pinnedChatIds);
        },
        loadPinnedChats: (state, action: PayloadAction<{ userId: string }>) => {
            const { userId } = action.payload;
            let storedPinnedChatIds: string[] = [];
            try {
                const item = localStorage.getItem(getStorageKey(userId));
                if (!item) return;

                const schema = z.array(z.string());
                const parsed = JSON.parse(item) as unknown;
                const validation = schema.safeParse(parsed);

                if (validation.success) {
                    storedPinnedChatIds = validation.data;
                } else {
                    console.error("Validation failed for pinned chat IDs", validation.error);
                }
            } catch (error) {
                console.error("Failed to parse pinned chat IDs from localStorage", error);
            }
            state.pinnedChatIds = storedPinnedChatIds;
        },
    },
});

export const { pinChat, unpinChat, loadPinnedChats } = pinChatSlice.actions;
export const pinChatReducer = pinChatSlice.reducer;
