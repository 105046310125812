import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { SCROLL_TO_PARAM_KEY } from "@/constants";

export const useScrollToSectionEffect = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(searchParams);

        const scrollTo = urlSearchParams.get(SCROLL_TO_PARAM_KEY);
        if (!scrollTo) return;

        const element = document.getElementById(scrollTo);
        if (!element) return;

        element.scrollIntoView({ behavior: "smooth" });

        urlSearchParams.delete(SCROLL_TO_PARAM_KEY);
        setSearchParams(urlSearchParams.toString());
    }, [location, searchParams, setSearchParams]);
};
